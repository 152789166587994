/* eslint-disable prettier/prettier */
interface Icons {
  [key: string]: string;
}
export const icons:Icons = {
  search: "/media/icons/duotune/general/gen021.svg",
  plus:   "/media/icons/duotune/arrows/arr075.svg",  // create
  pencil: "/media/icons/duotune/art/art005.svg",     // edit
  bin:    "/media/icons/duotune/general/gen027.svg",  // delete
  cross:  "/media/icons/duotune/arrows/arr061.svg",   // close / no
  tick: "/media/icons/duotune/arrows/arr012.svg",   // tick / yes
  calendar: "/media/icons/duotune/general/gen014.svg", // Booking nav
  heart_pulse: "/media/icons/duotune/medicine/med001.svg", // Patient nav
  credit_card: "/media/icons/duotune/finance/fin002.svg", // Billing nav
  paper_plane: "/media/icons/duotune/general/gen016.svg", // Communication nav
  person_circle: "/media/icons/duotune/communication/com006.svg", // HRM nav
  gear: "/media/icons/duotune/coding/cod001.svg", // Setting nav
  location_house: "/media/icons/duotune/maps/map008.svg", // Clinic nav
  bell: "/media/icons/duotune/general/gen007.svg", // for notifications and alerts
  paper: "/media/icons/duotune/general/gen005.svg", // Coding nav
  file: "/media/icons/duotune/files/fil003.svg",
  user: "/media/icons/duotune/general/gen049.svg", // User Icon
  save: "/media/icons/duotune/files/fil013.svg", // Save
  left: "/media/icons/duotune/arrows/arr022.svg", // Left
  right: "/media/icons/duotune/arrows/arr023.svg", // Right
  next: "/media/icons/duotune/arrows/arr064.svg", // Next
  location_pin: "/media/icons/duotune/general/gen018.svg", // specialist provider no
  broken_chain: "/media/icons/duotune/coding/cod008.svg", // reset password
  letter: "/media/icons/duotune/communication/com011.svg",
  phone: "/media/icons/duotune/electronics/elc002.svg",
  calendar_grid: "/media/icons/duotune/files/fil002.svg",
  boxes: "/media/icons/duotune/general/gen025.svg",
  print: "/media/icons/duotune/technology/teh006.svg",
  reset_password: "/media/icons/duotune/technology/teh004.svg",
};
export default icons;
